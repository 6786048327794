import { print } from 'graphql';
import { api } from "./api";

export const graphqlApi = async <T>(query, { variables = {} } = { variables: {} }) => {
	try {
		const {
			data: { data },
		} = await api.request<{ data: T }>({
			url: `/graphql`,
			method: "POST",
			data: {
				query: print(query),
				variables,
			},
		})
		return data
	} catch (e) {
		throw e
	}
}

export const setAuthHeader = (token: string) => {
	api.defaults.headers.common["authorization"] = "Bearer " + token;
}

export const removeAuthHeader = () => {
	const { authorization, ...rest } = api.defaults.headers.common;
	api.defaults.headers.common = rest;
}

export {
	api,
};

