/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import {UserProvider} from "./src/lib/context/UserContext"
import {Analytics} from "@vercel/analytics/react"

import React from "react"

export const wrapRootElement = ({element}) => {
    return (
        <>
            <Analytics/>
            <UserProvider>{element}</UserProvider>
        </>
    )
}
