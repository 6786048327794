import React, { useContext, useState } from "react"
import { removeAuthHeader, setAuthHeader } from "../api/graphqlApi"

interface UserContextInterface {
  user: any | null
  logIn: (user: any, jwt: string) => void
  logOut: () => void
}

export const UserContext = React.createContext<UserContextInterface>(undefined!)

const UserProvider = ({ children }) => {
  const [user, setUser] = useState<any | null>()

  const logIn = (user: any, jwt: string) => {
    setAuthHeader(jwt)
    setUser(user)
  }
  const logOut = () => {
    removeAuthHeader()
    setUser(null)
  }

  return <UserContext.Provider value={{ user, logIn, logOut }}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider")
  }
  return context
}

export { UserProvider }
