// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dokumenty-download-tsx": () => import("./../../../src/pages/dokumenty/download.tsx" /* webpackChunkName: "component---src-pages-dokumenty-download-tsx" */),
  "component---src-pages-dokumenty-index-tsx": () => import("./../../../src/pages/dokumenty/index.tsx" /* webpackChunkName: "component---src-pages-dokumenty-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kurzy-tsx": () => import("./../../../src/pages/kurzy.tsx" /* webpackChunkName: "component---src-pages-kurzy-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-partneri-tsx": () => import("./../../../src/pages/partneri.tsx" /* webpackChunkName: "component---src-pages-partneri-tsx" */),
  "component---src-pages-prihlaska-tsx": () => import("./../../../src/pages/prihlaska.tsx" /* webpackChunkName: "component---src-pages-prihlaska-tsx" */),
  "component---src-pages-vozidla-tsx": () => import("./../../../src/pages/vozidla.tsx" /* webpackChunkName: "component---src-pages-vozidla-tsx" */),
  "component---src-pages-vycvik-tsx": () => import("./../../../src/pages/vycvik.tsx" /* webpackChunkName: "component---src-pages-vycvik-tsx" */)
}

